module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-theme-antv","short_name":"gatsby-starter-theme-antv","start_url":"/","background_color":"#873bf4","theme_color":"#873bf4","display":"minimal-ui","icon":"/home/liaodingbai/Documents/workspace/ctaccel.github.io/node_modules/@antv/gatsby-theme-antv/site/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9772447a8d07a8fe19894b5176c6cb0d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/liaodingbai/Documents/workspace/ctaccel.github.io/node_modules/@antv/gatsby-theme-antv/site/layouts/layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#873bf4"},
    },{
      plugin: require('../node_modules/@antv/gatsby-theme-antv/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
